<template>
  <v-container>
    <div class="mt-10 text-center">ยืนยันตัวตนสำเร็จ</div>
    <div class="mt-5 mb-0 text-center">
      ตั้ง
      <strong class="red--text">รหัสผ่าน</strong> สำหรับเข้าสู่ระบบในครั้งถัดไป
    </div>
    <vue-pincode
      class="mt-0"
      ref="pincodeInput"
      @pincode="login"
      propShowText="สร้างรหัส PIN"
    />
  </v-container>
</template>

<script>
import VuePincode from "@/components/PinCode/VuePincode";

export default {
  name: "CreatePinCode",
  components: {
    VuePincode,
  },
  mounted() {
    // console.log(this.$headers['Authorization']);
    // console.log(this.$headers['headers']['Authorization']);
    // console.log(this.$headers['Authorization']);
    // console.log("Bearer " + localStorage.getItem("fun_token"));
    if (
      this.$headers["headers"]["Authorization"] !=
      "Bearer " + localStorage.getItem("fun_token")
    ) {
        this.$headers["headers"]["Authorization"]
       location.reload();
    }
  },
  methods: {
    // login(pincode){
    //     console.log(localStorage.getItem('fun_token'));
    // }
    async login(pincode) {
      let parameter = {
        to: "login",
        method: "create_pin",
        code: pincode,
      };

      await this.$store.dispatch("auth/TransferData", parameter).then(
        (response) => {
          //   if (response == "Success") {
          //   localStorage.removeItem("error_count");
          //   localStorage.removeItem("error_varify_time");
          this.$alert(
            "ใช้รหัสนี้เพื่อเข้าสู่ระบบในครั้งถัดไป",
            "สร้างรหัสผ่านสำเร็จ",
            "success"
          ).then(() => {
            this.$router.push("/SelectPeriod");
          });

          //   }
          //console.log(response);
        },
        (error) => {
          switch (error) {
            case "CODE_NOT_FOUND":
              //   // localStorage.setItem("blog_token", data.response.token);
              //   this.error_count += 1;
              //   console.log(this.error_count);
              //   localStorage.setItem("error_count", this.error_count);

              //   var now = new Date();
              //   localStorage.setItem("error_varify_time", now);

              //   this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});

              //   this.check_incorrect();
              break;
            default:
              alert(error);
              break;
          }
        }
      );
      // this.$router.push("/Dashboard");
      //goto main
      //   try {
      //     const { data } = await axios.post(`/login`, { pincode })
      //     this.$refs.pincodeInput.triggerSuccess();
      //     return { success: true, status: data.status }
      //   } catch (e) {
      //     this.$refs.pincodeInput.triggerMiss();
      //     return { success: false, status: e.response.data.status }
      //   }
    },
  },
};
</script>

<style scoped></style>
>
